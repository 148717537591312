'useClient'

import { useState } from 'react';
import './App.css';



function App() {

  const [id, setId] = useState('')
  const [student, setStudent] = useState(null)

  const handleClick = () => {
    console.log(id);
    getInformation()
  }

  const getInformation = async ()=>{
    try {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/students/search/${id}`)
      console.log(response);

      if (!response.ok){
        console.log('error');
        return
      }
      const data = await response.json()
      
      setStudent(data)
    } catch (error) {
      console.log(error);
    }
  }
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/students.pdf`;
    link.download = 'students.pdf'; // You can specify a different name for the downloaded file here
    link.click();
  };


  return (
    <div className="App">
      <header className="App-header">
        <br/>  
        <br/>

        <p>المدرسة العليا للفلاحة الصحراوية  أدرار</p>
        <br/>
        <br/>
        <img src='logo.png' className="App-logo" alt="logo" />
        <br/>
        <br/>
          <p>يرجى تحميل الملف  التالي و تتبع خطوات  للحصول على الايميل الجامعي مع الوصول الى تطبيق موودل</p>
        <br/>
        <button className='submit-button' onClick={handleDownload}>تحميل</button>

        
        
        <h1>معلومات عن تطبيق موودل</h1>
        <img src='moodle.png  ' className="App-logo" alt="logo" />

        <p className="App-intro">
        تطبيق مودل هو تطبيق للهواتف الذكية والأجهزة اللوحية يتيح للطلاب و الأساتذة الوصول إلى بيئة التعلم المبنية على منصة مودل. يوفر التطبيق مجموعة من الوظائف، بما في ذلك الوصول إلى مواد الدورات الدراسية، والمشاركة في الأنشطة، وتقديم الواجبات، وتلقي الإشعارات، والمشاركة في المناقشات، سواء كان المستخدم متصلاً بالإنترنت أم غير متصل. يهدف التطبيق إلى تعزيز مرونة وإمكانية الوصول إلى نظام إدارة التعلم مودل، مما يسهل على المستخدمين البقاء على اتصال والمشاركة في دوراتهم التعليمية في أي وقت ومن أي مكان. ويستخدم هذا التطبيق في هذه المدرسة العليا، حيث يُلزم الطلاب باستخدامه كجزء من العملية التعليمية.
        </p>
        <br/><br/><br/>
        <h1>معلومات عن  البريد الالكتروني و إستخداماته</h1>
        <img src='k9.webp' className="App-logo" alt="logo" />

        <p className="App-intro">
        البريد الإلكتروني التعليمي هو وسيلة اتصال رسمية تقدمها مؤسسات التعليم العالي للطلاب وأعضاء هيئة التدريس. يُستخدم هذا البريد الإلكتروني للتواصل مع الأساتذة، استلام الإعلانات والأخبار الأكاديمية، إرسال وتسليم الواجبات، والتسجيل في الأنشطة والدورات. يساعد البريد الإلكتروني التعليمي الطلاب على البقاء على اطلاع دائم بكل ما يتعلق بمسيرتهم التعليمية والتواصل بشكل رسمي وفعال داخل المجتمع الأكاديمي.   
             </p>
        <br/><br/><br/>
        <h1>معلومات الطالب</h1>
        <p className='description'>
            أدخل رقم التسجيل للحصول على المعلومات
        </p>
        <div>
        <input className='input-field' type='text' placeholder='161637001553' onChange={(v)=>setId(v.target.value)}/>
      </div>
        
        <br/><br/>
        {
          student == null ? null : <div>
            <p className='student-info'>
          الإسم:          {student ==null ? <br/> : student.firstname}
            <br/>
            اللقب:           {student ==null ? <br/> : student.lastname}
            <br/>
            الايميل: {student ==null ? <br/> : student.email}
            <br/>
            رقم التسجيل: {student ==null ? <br/> : student.registranNumber}
            <br/>
            إسم المستخدم لتطبيق موودل : {student ==null ? <br/> : student.registranNumber}
            <br/>
            كلمة المرور: 

          </p>
          <p className='student-pass'>{student ==null ? <br/> : student.password}</p>
          </div>
        }
        <br/><br/>
        <button className='submit-button' onClick={handleClick}>بحث</button>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </header>
      
    </div>
  );
}

export default App;
